import { createAsyncThunk } from '@reduxjs/toolkit'
import { NotificationService } from '../../services/api'
import { enqueueSnackbar } from '../reducers/alertReducer'

export const findNotifications = createAsyncThunk(
  'notification/findAll',
  async ({ skip, limit }: { skip: number; limit: number }, { dispatch }) => {
    try {
      const notifications = await NotificationService.findAll({
        skip,
        limit,
      })
      return notifications
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      throw e
    }
  },
)

export const readNotifications = createAsyncThunk(
  'notification/read',
  async ({ ids }: { ids: number[] }, { dispatch }) => {
    try {
      NotificationService.read({
        ids,
      })
      return ids
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      throw e
    }
  },
)

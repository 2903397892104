import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationDto } from '../../services/api'
import { PaginatedDto } from '../../services/extendedType'
import {
  findNotifications,
  readNotifications,
} from '../thunks/notificationThunk'

export interface NotificationState {
  notifications: {
    datas: NotificationDto[]
    totalCount: number
  }
}

const initialState: NotificationState = {
  notifications: {
    datas: [],
    totalCount: 0,
  },
}
const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        findNotifications.fulfilled,
        (state, { payload }: PayloadAction<PaginatedDto<NotificationDto>>) => {
          state.notifications = payload
        },
      )
      .addCase(readNotifications.fulfilled, (state) => {
        state.notifications.datas = state.notifications.datas.map((notif) => ({
          ...notif,
          isRead: true,
        }))
      })
  },
})

export default notificationSlice.reducer

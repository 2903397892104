import { makeStyles, Tooltip, IconButton } from '@material-ui/core'
import { useIntl } from 'react-intl'

const useStyles = makeStyles({
  iconBtn: {
    padding: 0,
  },
  icon: {
    marginRight: '1.5rem',
  },
})

type TooltipButtonProps = {
  title: string
  onClick: () => void
  icon: any
  disable?: boolean
  color?: any
  id?: string
}
export const TooltipButton = ({
  title,
  onClick,
  disable,
  color,
  icon,
  id,
}: TooltipButtonProps) => {
  const intl = useIntl()
  const classes = useStyles()
  return (
    <Tooltip title={intl.formatMessage({ id: title })}>
      <span>
        <IconButton
          size="small"
          color={color ? color : 'primary'}
          aria-label={intl.formatMessage({ id: title })}
          id={id}
          onClick={(e) => {
            e.stopPropagation()
            onClick()
          }}
          className={classes.iconBtn}
          disabled={disable}
        >
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  )
}

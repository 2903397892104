import { Grid, makeStyles } from '@material-ui/core'

import Logo from '../../assets/img/logos/logo.png'
import { Text } from '../texts'

const useStyles = makeStyles({
  text: {
    padding: 5,
    color: 'white',
  },
})

export function AuthTitle() {
  const classes = useStyles()

  return (
    <Grid container justifyContent="center" alignItems="center">
      <img height="50" src={Logo} alt="logo" />
      <Text text="app-name" variant="h2" className={classes.text} format />
    </Grid>
  )
}

import { Grid } from '@material-ui/core'

import { AuthCard } from '@components/cards'
import { FirstConnexionPatientForm } from '@components/forms'

export default function FirstConnection() {
  const Content = (
    <Grid container spacing={2} justifyContent="center">
      <FirstConnexionPatientForm />
    </Grid>
  )

  return (
    <AuthCard>
      <AuthCard.Header title="first-connexion" />
      <AuthCard.Content>{Content}</AuthCard.Content>
      <AuthCard.Actions backToLoginLink />
    </AuthCard>
  )
}

import { Box, Container, makeStyles } from '@material-ui/core'
import { Button } from '@components/buttons'

import NotFoundImage from '../../assets/img/NotFound.svg'

const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btn: {
    width: 500,
  },
  img: {
    width: 800,
  },
})

export default function NotFound() {
  const classes = useStyles()

  return (
    <Container className={classes.container}>
      <Box display="grid" justifyItems="center">
        <img src={NotFoundImage} alt="Not Found" className={classes.img} />
        <Button
          text="Home"
          textColor="primary"
          size="large"
          link="/"
          className={classes.btn}
        />
      </Box>
    </Container>
  )
}
